<template>
  <div id="app">
    <pre-loader v-if="app.state != STATE.LOADED" />
    <router-view  />
    <modal v-model="show"></modal>
  </div>
</template>
<script>
import '@/styles/reset.scss'
import '@/styles/main.scss'
import 'keen-ui/dist/keen-ui.css'
import EventBus from '@/includes/event-bus'
import { mapState } from 'vuex'
import { STATE } from '@/includes/enums'

export default {
  components: {
      PreLoader: () => import('@/components/pre-loader.vue'),
      Modal: () => import('@/components/modal.vue'),
  },
  data() {
    return {
        STATE,
        show: false,
    }
  },
  computed: {
    ...mapState({
      app: (state) => state.app
    }),
  },
  mounted() {
    this. $nextTick(function () {
        EventBus.$emit('TRACK_LOAD')
        document.querySelector('body').classList.remove('loading')
    })
  },
}
</script>

<style lang="scss">
:root {
    --section-padding-horisontal-default: 1rem;
    --section-padding-vertical-default: 5rem;
    @include sm {
    --section-padding-horisontal-default: 1.75rem;
    }
}
h1, h2, h3 {
    font-weight: 600;
    line-height: 1.2;
}
h1 {
    font-size: 2.25rem;
    margin-bottom: .9rem;
}
h2 {
    font-size: 1.75rem;
    font-size: clamp(1.75rem, 2*2vw, 3rem);
    margin-bottom: .9rem;
}
h3 {
    font-size: 1.35rem;
    margin-bottom: .5rem;
}
p, ul, ol, li {
    font-size: clamp(1rem, 1rem + (.1*1vw), 1.35rem);
    line-height: 1.3;
    margin-bottom: .9rem;
}
.sub-title {
    display: block;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 600;
    margin-bottom: .5rem;
    color: var(--accent-color-1);
    h1+&, h2+&, h3+& {
        margin: .5rem 0 0;
    }
}
section:not(.no-padding) {
    padding-left: var(--section-padding-horisontal-default);
    padding-right: var(--section-padding-horisontal-default);
    padding-top: var(--section-padding-vertical-default);
    padding-bottom: var(--section-padding-vertical-default);
}

.page {
    h1 {
        font-size: 1.8rem;
    }
    h2 {
        font-size: 1.5rem;
        margin: 2rem 0 1.5rem;
    }
    h3 {
        font-size: 1.15rem;
        margin: 1.5rem 0 1rem;
    }
}

</style>