import EventBus from '@/includes/event-bus'

export default {

    install(Vue, options) {

      if (!options.disabled && (!options.writekey || options.writekey.length === 0)) {
        console.warn('Please enter a Segment Write Key')
        return
      }

      const analytics = window.analytics = window.analytics || []

      if (analytics.initialize) {
        return
      }
  
      if (analytics.invoked) {
        if (window.console && console.error) {
          console.error('Segment snippet included twice.')
        }
        return
      }
  
      analytics.invoked = true;
  
      analytics.methods = [
        'trackSubmit',
        'trackClick',
        'trackLink',
        'trackForm',
        'pageview',
        'identify',
        'reset',
        'group',
        'track',
        'ready',
        'alias',
        'debug',
        'page',
        'once',
        'off',
        'on'
      ];
  
      analytics.factory = function (method) {
        return function () {
          const args = Array.prototype.slice.call(arguments)
          args.unshift(method)
          analytics.push(args)
          return analytics
        }
      }
  
      for (let i = 0; i < analytics.methods.length; i++) {
        const key = analytics.methods[i];
        analytics[key] = analytics.factory(key);
      }
  
      analytics.SNIPPET_VERSION = '4.1.0';
  
      analytics.load = function (key, options) {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.async = true
        script.src = 'https://cdn.segment.com/analytics.js/v1/'
          + key + '/analytics.min.js'
  
        const first = document.getElementsByTagName('script')[0]
        first.parentNode.insertBefore(script, first)
        analytics._loadOptions = options
      }
      analytics.debug(false)

      EventBus.$on('TRACK_LOAD', async () => {
        analytics.load(options.writekey)
      })
      EventBus.$on('TRACK_PAGE', async (payload) => {
        const { fullPath } = payload
        window.analytics.page(fullPath)
      })
      EventBus.$on('TRACK_IDENTIFY', async (payload) => {
        const { userId, traits } = payload
        console.log("TRACK_IDENTIFY", userId, traits)
        window.analytics.identify(userId, traits)
      })
      EventBus.$on('TRACK_EVENT', async (event) => {
        window.analytics.track(event)
      })
      EventBus.$on('TRACK_ERROR', async (properties) => {
        window.analytics.track('Error', properties)
      })
      EventBus.$on('TRACK_RESET', async () => {
        window.analytics.reset()
      })

    },

}
