import Vue from 'vue'
import { STATE } from '@/includes/enums'

const initial_state = () => ({
    state: STATE.INIT
})

const state = initial_state()

const actions = {
    ERROR({ commit }) {
        commit('SET_ERROR')
    },
    LOADING({ commit }) {
        commit('SET_LOADING')
    },
    LOADED({ commit }) {
        commit('SET_LOADED')
    }
}
const mutations = {
    RESET_STATE(state) {
        const s = initial_state()
        for (const key in s) {
            Vue.set(state, key, s[key])
        }
    },
    SET_ERROR(state) {
        state.state = STATE.ERROR
    },
    SET_LOADING(state) {
        state.state = STATE.LOADING
    },
    SET_LOADED(state) {
        state.state = STATE.LOADED
    },
}
const getters = {
}

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
}
