<template>
    <button
    class="button"
    :class="{ 'button--small' : small, 'button--large' : large }"
    >
        <span><slot /></span>
    </button>
</template>
<script>
export default {
	props: {
		large: Boolean,
        small: Boolean,
		loading: Boolean
	},
}
</script>
<style lang="scss">
.button {
    background-color: var(--accent-color-2);
    color: var(--secondary-color);
    padding: 0.8rem 2rem;
    border-radius: 100px;
    font-size: 1rem;
    font-weight: 600;
    transition: all .25s ease;

    &:hover {
        opacity: .9;
        transform: scale(1.025);
        transform-origin: center center;
    }

    &--small {
        font-size: 0.7rem;
        padding: 0.4rem 1rem;
    }
    &--large {
        padding: 1.1rem 2.25em;
    }
}
</style>