import Vue from 'vue'
import VueRouter from 'vue-router'
import EventBus from '@/includes/event-bus'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "account" */ '../router/pages/Home.vue'),
    meta: {}
  },
  {
    path: '/quiz',
    name: 'Quiz',
    component: () => import(/* webpackChunkName: "account" */ '../router/pages/Quiz.vue'),
    meta: {}
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})


/*
 * Send Tracking Events
 * 
 */

router.beforeEach((to, from, next) => {
  EventBus.$emit('TRACK_PAGE', to)
  next()
})

export default router